export default {
  page: {
    title: {
      base: 'Plačilni prehod Autopay',
      start: 'Izbira načina plačila',
      visadata: 'Visa Mobile',
      continue: 'Nadaljuj - Izbira načina plačila',
      wait: 'Pričakovanje',
      confirmation: 'Potrdilo plačila',
      thankYou: 'Hvala za plačilo',
      error: 'Napaka strani',
      errorNotFound: 'Stran ni najdena',
      secureBack: 'Nalaganje strani',
      maintenance: 'Spletna stran v vzdrževanju',
      regulationsApproval: 'Predpisi in politika zasebnosti',
      toTransferData: 'podatki o nakazilu',
      sessionTimeout: 'Stran po koncu seje',
      paywayError: 'Plačilni kanal ni na voljo'
    }
  },
  header: {
    availabilityAdjust: {
      increaseFontSize: 'Povečaj velikost pisave',
      decreaseFontSize: 'Zmanjšaj velikosti pisave',
      changeContrast: 'Spremeni kontrast',
      changeLanguage: 'Spremeni jezik strani',
      skipToContent: 'Preskoči na vsebino',
      skipToContentLabel: 'Preskoči na glavno vsebino',
      logoTitle: 'Logotip spletnega mesta'
    },
    sessionTimer: {
      timeOut: 'Seja je potekla',
      sessionEnd: 'Konec seje za:',
      label: 'Podaljšaj čas seje',
      button: 'Podaljšaj'
    }
  },
  footer: {
    text1: 'Ali imaš dodatna vprašanja v zvezi s z spletnimi plačili ali našimi dodatnimi storitvami? <a title="Odpre se v novem oknu" href="https://pomoc.autopay.pl/?pk_campaign=paywall&pk_kwd=start&pk_medium=hyperlink" target="_blank" rel="noreferrer">Pojdite na stran s pomočjo</a>',
    text2: {
      part1: 'Upravljavec osebnih podatkov je Autopay S.A.',
      part2: 'ul. Powstańców Warszawy 6, 81-718 Sopot. Posredovanje osebnih podatkov je prostovoljno, vendar je potrebna izpolnitev oddane izjave. Pravna podlaga, namen, obdobje obdelave osebnih podatkov in pravice uporabnika ter druge pomembne informacije v zvezi z načeli obdelave osebnih podatkov so podrobno opisani v <a href="https://bluemedia.pl/storage/app/media/Bluemedia_pl/Dokumenty/polityka- prywatnosci.pdf" target="_blank" title="Pojdite na stran z dokumentom Politika zasebnosti (128 KB, PDF)" rel="noreferrer">Politika zasebnosti družbe Autopay S.A.</a>'
    },
    linkShowMoreTitle: 'Prikaži dodatne informacije',
    linkShowMoreText: 'Preberi več',
    linkShowLessTitle: 'Skrij dodatne informacije',
    linkShowLessText: 'Preberi manj'
  },
  paywall: {
    header: 'Izberi način plačila',
    info: {
      paywayNotAvailable: 'Zelo nam je žal, vendar izgleda, da izbrani način plačila v Tvoji napravi trenutno ni mogoč.',
      applePayNotAvailable: 'Zelo nam je žal, vendar izgleda, da plačevanje z Apple Pay v Tvoji napravi trenutno ni mogoč.',
      googlePayNotAvailable: 'Zelo nam je žal, vendar izgleda, da palčevanje z Google Pay v Tvoji napravi trenutno ni mogoč.'
    },
    paywayGroup: {
      inactiveMessage: 'Plačilo trenutno ni na voljo',
      notice: {
        novelty: 'NOVOST',
        promotion: 'POSEBNA PONUDBA',
        recommended: 'PRIPOROČAMO',
        mastercard: 'HITRO IN VARNO',
        mastercardC2P: 'HITRO IN VARNO',
        blik: 'BLIKOMANIA - Registriraj se in osvoji diamante!',
        visaMobile: 'Plačajte gladko in varno'
      },
      bank: {
        many: 'bank',
        one: 'banka',
        two: 'banki'
      },
      wallet: {
        many: 'denarnice',
        one: 'denarnica',
        two: 'denarnici'
      },
      otp: {
        many: 'načine',
        one: 'način',
        two: 'načine'
      },
      blikPayLater: {
        info: 'Izvedi več'
      }
    },
    paywayList: {
      label: 'Skupina vrste plačila',
      linkSelectOther: 'Izberi drugo'
    },
    paywaySingle: {
      accountAtAnotherBank: 'Başka bankada hesabım var'
    },
    paywaySwitch: {
      methodShortSingle: 'Bir kere',
      methodLongSingle: 'Tek seferlik ödemeler',
      methodShortAuto: 'Döngüsel',
      methodLongAuto: 'Yinelenen ödemeler'
    },
    form: {
      payway: {
        label: 'Izberi način plačila'
      },
      card: {
        label: 'Vnesi podatke kartice',
        iframe: {
          title: 'Obrazec s podatki o kreditni kartici, ki ga je treba izpolniti'
        }
      },
      visaMobile: {
        iframe: {
          title: 'Obrazec s telefonsko številko za izpolnitev'
        },
        status: {
          pending: {
            statusText: 'Čakanje na potrditev',
            header: 'Potrdi plačilo v aplikaciji',
            descriptionText1: 'Plačilo boste potrdili v aplikaciji Visa Mobile ali v bančni aplikaciji z aktivno storitvijo Visa Mobile',
            descriptionText2: 'Po potrditvi transakcije v aplikaciji počakajte na rezultat opravljenega plačila',
            descriptionText3: 'Preklic transakcije v teku',
            cancelButton: 'Prekliči plačilo'
          },
          success: {
            statusText: 'Hvala za nakup!',
            descriptionText: 'Plačilo uspešno izvedeno'
          },
          error: {
            statusText: 'Preveri, da imaš dovolj sredstev na plačilni kartici za izvršitev transakcije Visa Mobile.',
            descriptionText: 'Plačilo zavrnjeno'
          }
        },
        apps: 'Mobilne plačilne aplikacije Visa Mobile',
        help: 'Potrebuješ pomoč?',
        info: 'Več o tem',
        secure: 'Transakcija je zahvaljujoč SSL šifriranju varna',
        modal2: {
          header: 'Za potrditev plačila odpri aplikacijo Visa Mobile ali aplikacijo banke, v kateri imaš aktivno storitev Visa Mobile',
          listText1: 'imaš inštalirano aplikacijo Visa Mobile ali aktivno uslugo Visa Mobile v mobilni aplikaciji tvoje banke',
          listText2: 'imaš omogočena obvestila v aplikaciji  Visa Mobile ali v aplikaciji banke, v kateri imaš aktivno storitev Visa Mobile',
          listText3: 'navedena številka je veljavna: <strong class="no-wrap">+{prefix} {phone}</strong>',
          listText4: 'Preveri tudi, da...'
        }
      },
      click2pay: {
        loaderText: 'Iščemo vaš e-poštni naslov<br>v <strong>Click to Pay</strong>',
        saveCard: {
          text1: 'Shrani svojo kartico',
          text2: 'Vaši podatki bodo varno shranjeni v <strong>Mastercard Click to Pay</strong>',
          link: 'Več o tem'
        },
        email: {
          labelForClick2Pay: 'E-poštni naslov za obvestila o plačilih'
        },
        cardSelect: {
          selected: 'Izbrano',
          select: 'Izberi',
          payOther: 'Vnesite podatke o kartici',
          back: 'Nazaj'
        },
        cardSelected: {
          header: 'Uporabite kartico, shranjeno v Click to Pay',
          date: 'velja do',
          buttonChange: 'Spremeni'
        },
        codeForm: {
          description: 'Našli smo vaš e-poštni naslov v storitvi Click to Pay.',
          codeSentEmail: 'Vnesite enkratno kodo, ki smo jo poslali na {email}, da se prijavite v svoj račun.',
          codeSentPhone: 'Vnesite enkratno SMS kodo, ki smo jo poslali na {phone}, da se prijavite v svoj račun.',
          codeLabel: 'Vnesite kodo',
          codePlaceholder: 'vnesite kodo',
          codeLabelFlow: 'Koda',
          codeSendLabel: 'Pošlji novo kodo',
          codeSendAriaLabel: 'preko',
          codeSendAriaLabel2: 'ali',
          buttonSendNewSms: 'SMS',
          buttonSendNewEmail: 'email',
          trustedLabel: 'Dodajte to napravo na svoj seznam zaupanja vrednih<br><span>Naslednjič bomo prepoznali to napravo in plačali boste brez prijave</span>',
          buttonSubmit: 'Naprej',
          buttonChangeCard: 'Vnesite podatke o kartici'
        },
        info: {
          description: 'Plačilo s kartico je šifrirano in varno. Transakcija bo avtorizirana z uporabo 3DSecure na spletni strani banke.'
        },
        loginForm: {
          emailLabel: 'Vnesite svoj e-poštni naslov za prijavo v Click to Pay',
          emailPlaceholder: 'e-poštni naslov',
          emailLabelFlow: 'E-pošta',
          buttonSubmit: 'Prijava',
          buttonBack: 'Nazaj'
        },
        newUserForm: {
          header: 'Shrani kartico v Click to Pay',
          description: 'Potrebujemo še nekaj podrobnosti, da shranimo vašo kartico.',
          emailLabel: 'Vnesite e-poštni naslov',
          emailPlaceholder: 'e-poštni naslov',
          emailLabelFlow: 'E-pošta',
          countryLabel: 'Država',
          firstnameLabel: 'Ime',
          firstnamePlaceholder: 'ime',
          firstnameLabelFlow: 'Ime',
          lastnameLabel: 'Priimek',
          lastnamePlaceholder: 'lastname',
          lastnameLabelFlow: 'Priimek',
          phoneLabel: 'Telefonska številka',
          phonePlaceholder: 'telefonska številka',
          phoneLabelFlow: 'Telefon',
          countryCodePlaceholder: 'koda',
          countryCodeLabelFlow: 'Koda',
          trustedLabel: 'Dodajte to napravo na svoj seznam zaupanja vrednih<br><span>Naslednjič bomo prepoznali to napravo in plačali boste brez prijave</span>',
          TermsLabel: 'Z nadaljevanjem sprejemate <a href="https://www.mastercard.com/global/click-to-pay/country-listing/terms.html" target="_blank">pogoje uporabe</a> in razumete, da bodo vaši podatki obdelani v skladu z <a href="https://www.mastercard.com/global/click-to-pay/country-listing/privacy.html" target="_blank">Politika zasebnosti</a>Mastercard.',
          c2pInfo: 'Klikni za plačilo bo te podatke uporabil za vašo verifikacijo in pošiljanje potrditvenih kod na to številko. Morda boste morali plačati stroške sporočil/podatkov.',
          buttonSubmit: 'Prihrani in plačaj',
          buttonBack: 'Plačaj brez shranjevanja kartice'
        },
        profile: {
          header: 'Prijavite se za Click to Pay',
          buttonChangeEmail: 'Spremeni e-poštni naslov'
        },
        modal: {
          title: 'Click to Pay - informacije',
          header: 'Plačajte hitro in varno, brez prepisovanja podatkov o kartici v novem standardu Click to Pay',
          text1: 'Pri plačilu s kartico poiščite logotip <img src="/web/img/click2pay/Click_to_Pay.svg" style="height: 14px" alt="Click to Pay Logo"> in plačajte s svojo plačilno kartico hitro in priročno.',
          text2: 'Plačaj s klikom je nov standard plačil s kartico, ki ga podpirata Visa in Mastercard.',
          text3: 'S kartico na vašem računu Click to Pay lahko plačujete v trgovinah na Poljskem in v tujini, ne da bi kopirali podatke kartice.',
          text4: 'Podatki so varno shranjeni v Visa ali Mastercard. Vse informacije so v celoti šifrirane.',
          text5: 'Med nadaljnjimi plačili bo Click to Pay prepoznal vaš e-poštni naslov, tako da lahko takoj plačate s shranjenimi karticami.',
          text6: 'Plačila dodatno potrdite pri vaši banki, kar zagotavlja popolno varnost.',
          processError: 'Med izvajanjem plačila s klikom za plačilo je prišlo do napake. Izvedite standardno plačilo s kartico.'
        }
      },
      email: {
        label: 'Vnesi e-poštni naslov',
        flowLabel: 'E-pošta',
        tip: 'Potrebujemo Tvoj e-poštni naslov, da lahko pošiljamo sporočila o stanju plačila.'
      },
      terms: {
        header: 'Soglasje in izjave',
        selectAll: 'Označi vse',
        fieldNotRequired: 'Neobvezno polje',
        partnerMarketingTextLess: 'Ali želiš prejemati e-poštna, besedilna ali telefonska obvestila o najboljših ponudbah, promocijah, tekmovanjih in drugih zanimivih dogodkih, ki jih organiziramo mi ali naši partnerji?',
        partnerMarketingTextMore: 'Obljubljamo, da ne bomo pošiljali neželene pošte! Če te naša sporočila dolgočasijo, lahko svoje soglasje kadar koli prekličeš.',
        tradeMarketingTextLess: 'Ali želiš, da lahko Tvojo e-pošto ali telefonsko številko posredujemo našim zaupanja vrednim partnerjem, da lahko predstavijo zanimive informacije in ponudbe?',
        tradeMarketingTextMore: 'Zagotavljamo, da so naši partnerji uveljavljena podjetja, ki tako kot mi ne marajo pošiljanja neželene pošte in lahko svoje soglasje kadar koli prekličeš.'
      },
      termsLabelDefault: 'Prebral sem in sprejemam <a href="{regulationUrl}" class="form-text-link" target="_blank" title="Prenesi datoteko s pogoji in določili" rel="noreferrer">Pogoji in določila</a> za opravljanje plačilnih storitev in <a href="{privacyPolicyUrl}" class="form-text-link" target="_blank" title="Prenesi datoteko s pravilnikom o zasebnosti Autopay S.A.">Pravilnik o zasebnosti</a>. Želim, da je storitev opravljena takoj, v primeru odstopa od pogodbe pa vem, da za opravljene storitve na mojo zahtevo ne bom prejel vračila stroškov do odstopa od pogodbe.',
      termsReccuring: 'Če želiš izvesti plačilo, sprejmi <a href="{regulationUrl}" class="form-text-link" target="_blank" title="Prenesi datoteko s pogoji storitve" rel="noreferrer">Pogoji in določila</a> i <a href="{privacyPolicyUrl}" class="form-text-link" target="_blank" title="Prenesi datoteko s pravilnikom o zasebnosti Autopay S.A.">Pravilnik o zasebnosti</a> Autopay S.A., strinjam se s takojšnjo izvedbo plačilne storitve in strinjam se s obremenitvijo moje plačilne kartice.',
      termsReccuringMore1: 'Strinjam se, da bo storitev opravljena takoj, v primeru odstopa od pogodbe pa vem, da do odstopa od pogodbe ne bom prejel vračila za opravljene storitve na mojo zahtevo.',
      termsReccuringMore2: 'Strinjam se s cikličnim zaračunavanjem s strani Autopay S.A. moje plačilne kartice za dokončanje transakcije za {receiverName}. Pravila za ciklično bremenitev kartice so določena v <a href="{regulationUrl}" class="form-text-link" target="_blank" title="Prenesite datoteko s pravili storitve" rel="noreferrer">Pravilnik</a> za opravljanje plačilnih storitev. Izjavljam, da sem seznanjen z datumi, pogostostjo in pravili za določanje zneskov, ki bremenijo mojo plačilno kartico, ter načinom preklica tega soglasja, ki sem ga določil pri {receiverName}. Če bom imel kakršna koli vprašanja o ponavljajočih se bremenitvah moje plačilne kartice, se bom obrnil na {receiverName}.',
      mwfClauseLess: 'Če želiš izvesti plačilo, sprejmi pogoje <a href="{regulationUrl}" target="_blank" title="Pogoji storitve" rel="noreferrer">Pogoji</a> i <a href="{privacyPolicyUrl}" target="_blank" title="Politika zasebnosti Autopay S.A.">politika zasebnosti Autopay S.A.</a>, soglasje za začetek transakcij v vaši banki prek Autopay S.A. in dostavi podrobnosti te transakcije prejemniku plačila in zahteva za takojšnjo izvedbo plačilne storitve.',
      mwfClauseMore: 'Želim, da se storitev zagotovi takoj in vem, da v primeru odstopa od pogodbe ne bom prejel/a povračila.',
      mwfClauseARIA: 'Ödeme yapmak istiyorsanız, Autopay S.A.\'nın Düzenlemelerini ve gizlilik politikasını kabul edin, Autopay S.A. aracılığıyla bankanızda işlem başlatmaya izin verin. ve işlemin ayrıntılarını alacaklıya teslim etmek ve ödeme hizmetinin derhal yürütülmesini talep etmek. Hizmetin bir an önce sağlanmasını istiyorum ve sözleşmeden caymam durumunda para iadesi almayacağımı biliyorum. <a href="{regulationUrl}" class="form-text-link" target="_blank" title="" rel="noreferrer" tabindex="-1">Pobierz plik z regulaminem usługi</a> <a href="{privacyPolicyUrl}" class="form-text-link" target="_blank" title="" tabindex="-1">Dosyayı Autopay S.A.\'nın gizlilik politikasından indirin.</a>',
      paymentInfo: 'Plačilni nalog je oddan prek Autopay S.A. s sedežem v & nbsp; Sopotu in & nbsp; bo izvedeni v skladu s pogoji, ki jih določi vaša banka. Ko izberete banko, boste odobrili plačilo.',
      changePaywayLink1: 'Še vedno lahko',
      changePaywayLink2: 'Spremeni način plačila',
      linkMore: 'VEČ',
      linkShowMoreTitle: 'Ek bilgileri görüntüleyin',
      linkLess: 'MANJ',
      linkShowLessTitle: 'Ek bilgileri gizle',
      submitButton: {
        visaMobile: 'Plačati z',
        fastTransfer: 'Ustvari podrobnosti o prenosu',
        mwf: 'Potrjujem in plačam',
        pis: 'Potrjujem in začenjam s plačilom',
        auto: 'Yinelenen ödeme siparişi verin',
        autoPayment: 'Plačilo naročila',
        other: 'Plačaj',
        orange: 'Potrdi',
        c2pNext: 'Naprej',
        c2pAccept: 'Sprejmem',
        c2pSaveAndPay: 'Prihranite in plačajte'
      }
    },
    frData: {
      pageTitle: 'Podrobni podatki za nakazilo',
      header: 'Naroči prenos z uporabo podane podatkov',
      nrbLabel: 'Prejemnikov račun',
      nameLabel: 'Ime Prejemnika',
      addressLabel: 'Naslov Prejemnika',
      titleLabel: 'Naslov prenosa',
      amountLabel: 'Znesek prenosa',
      timeLabel: 'Izvedi transakcijo do',
      linkCopy: 'Kopiraj',
      linkGetPdf: 'Prenesi PDF s podatki',
      linkGetPdfTitle: 'Odpre se v novem oknu',
      linkGoToBank: 'Prijavi se v banko',
      info: 'Prijavi se v svoj bančni račun. Kopiraj podatke v nakazilo. Pomembno je, da so podatki enaki, ne spreminjaj zneska, valute, naslova itd.'
    },
    processing: {
      pageTitle: 'Čakalna stran za obdelavo transakcije',
      loaderAnimation: 'Animacija, prikazana med čakanjem',
      header: 'Pripravljamo Tvojo plačilno stran.',
      description: 'Počakaj še malo...',
      info: {
        header: 'Zapomni!',
        description: 'Po zaključku transakcije ne zapiraj strani - v tem primeru denar ne bo poslan prejemniku.'
      },
      loaderText: 'Prenos podatkov poteka.<br>To nam bo vzelo maksimalno 30 sekund.'
    }
  },
  thankYou: {
    pageTitle: 'Informacije o rezultatu transakcije',
    form: {
      emailLabel: 'E-pošta',
      buttonSubmit: 'Vrni se na partnersko spletno stran'
    },
    negative: {
      header: 'Plačilo ni bilo obdelano zaradi pomanjkanja avtorizacije transakcije.',
      description: 'Če še vedno želiš uporabiti ponudbo določenega prodajalca ali ponudnika storitev, se vrni na njihovo spletno stran, izberi izdelek ali storitev in plačaj z izbranim načinom plačila.'
    },
    pending: {
      header: 'Zahvaljujemo se za naročilo plačila prek plačilnega portala.',
      description: 'Trenutno preverjamo status transakcije. Ko bo postopek zaključen, boste v svoj e-poštni predal prejel informacije o stanju plačila.'
    },
    positive: {
      header: 'Hvala, Tvoje plačilo je bilo obdelano.',
      description: 'Potrditev bomo poslali na e-poštni naslov, ki ste ga navedeli.'
    }
  },
  regulationsApproval: {
    header: 'İşlemi kabul et'
  },
  wait: {
    pageTitle: 'Čakalna stran'
  },
  sessionTimeout: {
    pageTitle: 'Stran po koncu seje',
    header: 'Čas za dokončanje plačila je potekel',
    description: 'Kadar koli se lahko vrneš na spletno mesto partnerja, ponovno naročiš ali uporabiš storitev in jo plačaš na izbrani način.'
  },
  paywayError: {
    pageTitle: 'Izbrani plačilni kanal ni na voljo',
    header: 'Na žalost izbrani način plačila trenutno ni na voljo.',
    description: 'Vrnite se na partnersko stran in poskusite znova pozneje.'
  },
  loader: {
    defaultAnimation: 'Med nalaganjem strani se prikaže animacija',
    slider: 'Drsnik s spreminjanjem besedil',
    text1: 'V družbi Autopay si prizadevamo za ogljično nevtralnost. Z uporabo naših storitev to počneš tudi Ti.Tudi to počneš z uporabo naših storitev.',
    text2: 'V družbi Autopay se na delo vozimo s kolesom, s čimer zmanjšujemo emisije toplogrednih plinov v ozračje. To počneš tudi Ti z uporabo naših storitev.',
    text3: 'V družbi Autopay nas poganja zelena energija. Tebe tudi, če uporabljaš naše storitve.',
    text4: 'V podjetju Autopay zmanjšujemo porabo papirja. To počneš tudi Ti z uporabo naših storitev.',
    rateLimitExceeded: 'There is increased transaction traffic, so processing your payment may take longer than usual. Please wait and do not refresh the payment page.'
  },
  orderDetails: {
    header: 'Podrobnosti',
    headerAriaLabel: 'Podrobnosti transakcije',
    amountLabel: 'Znesek plačila',
    receiverLabel: 'Prejemnik',
    orderLabel: 'Številka naročila',
    amountOryginalCurrencyLabel: '{currency} cinsinden tutar',
    currencyRateLabel: 'Döviz kuru',
    feeLabel: 'Provizija za transakcijo',
    customerNumberLabel: 'Številka stranke',
    invoiceNumberLabel: 'Številka računa',
    accountNumberLabel: 'Številka bančnega računa',
    paywayLabel: 'Ödeme şekli',
    paywayName: 'Çevrimiçi banka havalesi',
    linkBack: 'Vrnitev v trgovino',
    linkBackTitle: 'Zapusti transakcijo in se vrni v trgovino',
    linkMore: 'Več',
    linkLess: 'Manj',
    toggleBtnUnfoldAriaLabel: 'Razširi podrobnosti naročila',
    toggleBtnFoldAriaLabel: 'Navij podrobnosti naročila'
  },
  error: {
    pageTitle: 'Stran z napako',
    linkBackUrlSet: 'Vrni se na partnersko spletno stran',
    linkBackUrlNotSet: 'Pojdi na stran za pomoč',
    sessionTimeout: {
      title: 'Čas za dokončanje plačila je potekel',
      message: 'Kadar koli se lahko vrneš na spletno mesto partnerja, ponovno naročiš ali uporabiš storitev in jo plačaš na izbrani način.'
    },
    notFoundError: {
      pageTitle: 'Stran z napako 404 – strani ni mogoče najti',
      title: 'Izgleda, da ste odjadrali predaleč in niste našli strani, ki jo želite obiskati.',
      message: 'Možni razlogi: stran ne obstaja ali je bila odstranjena, vzdrževanje je v teku, naslov strani je napačen, prišlo je do tehnične napake.'
    },
    generalError: {
      title: 'Opravičujemo se',
      message: 'Trenutno ne moremo obdelati Tvojega prenosa.'
    },
    errFieldNotFound: {
      title: 'Opravičujemo se',
      message: ''
    },
    errBadClientSource: {
      title: 'Opravičujemo se',
      message: ''
    },
    nrParametersError: {
      title: 'Opravičujemo se',
      message: 'Trenutno ne moremo obdelati Tvojega prenosa.'
    },
    transactionOutdated: {
      title: 'Čas za dokončanje plačila je potekel.',
      message: 'Ponovno ga opravi.'
    },
    linkValidityTimeOutdated: {
      title: 'Čas za dokončanje plačila je potekel.',
      message: 'Ponovno ga opravi.'
    },
    transactionValidityTimeOutdated: {
      title: 'Čas za dokončanje plačila je potekel.',
      message: 'Ponovno ga opravi.'
    },
    multiplyTransaction: {
      title: 'Opravičujemo se',
      message: 'Transakcija že obstaja in čaka na plačilo.'
    },
    transactionCanceled: {
      title: 'Opravičujemo se',
      message1: 'Transakcija je bila preklicana.',
      message2: 'Ponovno jo naroči.'
    },
    multiplyPaidTransaction: {
      title: 'Opravičujemo se',
      message: 'Transakcija je bila že plačana.'
    },
    transactionReject: {
      title: 'Opravičujemo se',
      message1: 'Ups, nekaj je šlo narobe.',
      message2: 'Prehod že popravljamo...'
    },
    bankDisabled: {
      title: 'Opravičujemo se',
      message: 'Izbrana banka trenutno ni na voljo.'
    },
    bankTemporaryMaintenance: {
      title: 'Opravičujemo se',
      message: 'Trenutno ne moremo obdelati Tvojega prenosa.'
    },
    insufficientStartAmount: {
      title: 'Opravičujemo se',
      message: 'Omejitev zneska prenosa je bila presežena.'
    },
    startAmountOutOfRange: {
      title: 'Opravičujemo se',
      message: 'Omejitev zneska prenosa je bila presežena.'
    },
    nonAccountedLimitExceeded: {
      title: 'Opravičujemo se',
      message: 'Plačila ni mogoče izvesti, ker je prodajalec dosegel mesečno omejitev prodaje.'
    }
  },
  confirmation: {
    pageTitle: 'Informacije o poteku plačila',
    linkBack: 'Grem nazaj na spletno mesto',
    linkBackTimeout: 'Vrnitev na spletno stran partnerja čez ',
    incorrectAmount: {
      header: 'Napačen znesek',
      description: 'Vašega plačila nismo nakazali prejemniku, ker je bil znesek napačen.',
      description2: 'Kliknite gumb, da se vrnete na stran in jo ponovite. Ne pozabite, da ne spreminjate nobenih podatkov.'
    },
    multiplePaid: {
      header: 'Dvojni depozit',
      description: 'Prejemnik je dvakrat prejel vaše plačilo. Ne skrbite – dobili boste povračilo.',
      description2: 'Kontaktirajte prejemnika in mu posredujte potrditev plačila.'
    },
    negative: {
      header: 'Brez plačila',
      description: 'Med obdelavo vašega plačila je prišlo do napake. Ampak nič ni izgubljeno.',
      description2: 'Kliknite gumb, da se vrnete na stran in jo ponovite.'
    },
    notFound: {
      header: 'Brez plačila',
      description: 'Med obdelavo vašega plačila je prišlo do napake. Ampak nič ni izgubljeno.',
      description2: 'Kliknite gumb, da se vrnete na stran in jo ponovite.'
    },
    outdated: {
      header: 'Konec časa',
      description: 'Vašega plačila nismo nakazali prejemniku.',
      description2: 'Kliknite gumb, da se vrnete na stran in jo ponovite.'
    },
    outdatedPaidNotRefunded: {
      header: 'Konec časa',
      description: 'Vašega plačila nismo nakazali prejemniku. Če je bil denar dvignjen z vašega računa, ne skrbite, povračilo boste prejeli v nekaj dneh.',
      description2: 'Kliknite gumb, da se vrnete na stran in jo ponovite.'
    },
    outdatedPaidRefunded: {
      header: 'Konec časa',
      description: 'Vašega plačila nismo nakazali prejemniku. Če je bil denar dvignjen z vašega računa, ne skrbite, že smo ga vrnili.',
      description2: 'Kliknite gumb, da se vrnete na stran in jo ponovite.'
    },
    pending: {
      header: 'V teku',
      description: 'Obdelujemo vaše plačilo. Ko bo prispel do prejemnika, vas bomo obvestili.'
    },
    positive: {
      header: 'Plačano',
      visaVideo: 'Oglasni video, predvajan po uspešni transakciji',
      description: 'Vaša sredstva smo nakazali prejemniku.'
    }
  },
  messages: {
    accessForbidden: 'Ni dostopa',
    internalServerError: 'Prišlo je do nepričakovane napake. Prosim poskusi kasneje..',
    validators: {
      required: 'Obvezno polje',
      pattern: 'Navedite veljavno vrednost',
      emailRequired: 'Zahtevan je e-poštni naslov.',
      emailNotValid: 'Tvoj e-poštni naslov vsebuje napako. Prosimo, vnesi veljaven e-poštni naslov',
      emailNotRecognized: 'Navedeni e-poštni naslov ni registriran',
      emailIdentityLookup: 'E-poštnega naslova ni bilo mogoče preveriti',
      emailLocked: 'Navedeni e-poštni naslov je začasno blokiran v sistemu Mastercard',
      paywayRequired: 'Izberi način plačila',
      creditCardRequired: 'Tüm alanları doldurun',
      phoneRequired: 'Prosim vpiši veljavno telefonsko številko',
      regulationRequired: 'Sprejmi pravila',
      requiredConsent: 'Zahtevano soglasje',
      codeNotValid: 'Vnesena koda ni veljavna',
      codeAccountLocked: 'Račun je bil začasno blokiran v sistemu Mastercard'
    },
    validatorsAria: {
      required: 'Pozor. Polje obrazca ni izpolnjeno. Zahtevano polje',
      pattern: 'Pozor. Polje vsebuje neveljavno vrednost.',
      emailRequired: 'Pozor. E-poštno polje ni izpolnjeno. Navedite pravilen e-poštni naslov.',
      emailNotValid: 'Pozor. Vaš e-poštni naslov vsebuje napako. Navedite pravilen e-poštni naslov',
      emailNotRecognized: 'Pozor. Navedeni e-poštni naslov ni registriran.',
      emailIdentityLookup: 'Pozor. Navedenega e-poštnega naslova ni bilo mogoče preveriti',
      emailLocked: 'Pozor. Navedeni e-poštni naslov je začasno blokiran v sistemu Mastercard.',
      paywayRequired: 'Pozor. Plačilo ni bilo izbrano. Izberite način plačila.',
      creditCardRequired: 'Pozor. Izpolnite vsa polja s podatki o kreditni kartici.',
      phoneRequired: 'Pozor. Polje telefonske številke vsebuje napako. Prosim vpiši veljavno telefonsko številko.',
      codeNotValid: 'Pozor. Navedena koda ni veljavna.',
      codeAccountLocked: 'Pozor. Račun je v sistemu Mastercard začasno blokiran.'
    },
    success: {
      copy: 'Kopirano!',
      codeSent: 'Koda je poslana!'
    },
    errors: {
      getPdf: 'Ni uspelo prenesti datoteke PDF!',
      copy: 'Kopiranje ni uspelo!',
      thankYouUpdate: 'Ni uspelo shraniti e-poštnega naslova!',
      groupInactive: 'Trenutno ni mogoče izvesti prenosa s skupino {groupName}. Izberite drugo plačilno sredstvo.',
      paywayInactive: 'Trenutno zaradi tehničnih razlogov ni mogoče opraviti prenosa iz izbrane banke. Izberi drugo banko ali način plačila..',
      googlePayInactive: 'Žal plačilo Google Pay v Tvoji napravi ni na voljo.',
      applePayInactive: 'Apple Pay je na voljo tistim, ki uporabljajo brskalnik Safari in naprave iOS.',
      codeNotSent: 'Ponovno pošiljanje kode ni uspelo!',
      codeCounterExceeded: 'Število dovoljenih poskusov je bilo preseženo. Ročno vnesite podatke o kartici ali uporabite drugo plačilno sredstvo.'
    }
  },
  modal: {
    closeButton: 'Zaprite',
    confirmButton: 'Razumem'
  },
  configuration: {
    paywayGroups: {
      auto: {
        label: 'Samodejno plačilo',
        description: 'Določiti je treba, kakšen naj bo opis...',
        labelInitWithRefund: 'Verifikacijsko plačilo',
        labelInitWithRefundOrange: 'Dodajte plačilno kartico',
        labelInitWithPayment: 'Plačilo s karticom',
        descriptionInitWithRefund: 'Vnesi podatke o kartici, ki jo želiš preveriti za prihodnja plačila. V naslednjem koraku bomo priloženo kartico obremenili z 1 PLN, da preverimo pravilnost podatkov. Po preverjanju ti bomo vrnili znesek',
        descriptionInitWithPayment: 'Vnesi podatke o kartici, ki jo želiš uporabili za izvedbo transakcije, in jo hkrati preveri za prihodnja plačila.'
      },
      blik: {
        label: 'BLIK',
        description: 'Takoj plačaj z BLIK-om, prijava ali registracija nista potrebni'
      },
      card: {
        label: 'Plačilo s kartico',
        description: 'Plačaj s svojo plačilno kartico'
      },
      pbl: {
        label: 'Spletni prenos',
        description: 'Izberite banko, pri kateri želite izvesti plačilo',
        descriptionVerify: 'Çevrimiçi kimlik doğrulama',
        descriptionServiceVerify: 'Izberite banko, ki vam bo omogočila preverjanje spletne storitve'
      },
      visaMobile: {
        label: 'Visa Mobile',
        description: 'Plačilo Visa Mobile'
      },
      wallet: {
        label: 'Virtualna denarnica',
        description: 'Plačaj brez prijave v spletno banko'
      },
      installments: {
        label: 'Razporedi plačilo na obroke',
        description: 'Plačajte svoje nakupe s priročnimi obroki'
      },
      alior: {
        descriptionInfo1: 'Plačajte svoje nakupe s priročnimi obroki',
        descriptionInfo2: '(preveri podrobnosti)',
        aliorModal: {
          text1: 'Glede na nastavitve spletnega mesta, kjer kupujete, lahko izdelke na obroke kupujete v naslednjih variantah:',
          text2: '<ul><li>10 obrokov 0 %</li><li>20 obrokov 0 %</li><li>od 3 do 48 obrokov z mesečnimi stroški 1 % (v skladu z danim reprezentativnim primerom za določeno transakcijo)</li></ul>'
        }
      },
      otp: {
        label: 'Plačaj kasneje',
        description: 'Kupi zdaj plačaj pozneje',
        descriptionInfo1Mwf: 'Plačajte račun kasneje - do 45 dni naenkrat ali v več enakih obrokih',
        descriptionInfo1Ecommerce: 'Plačajte kasneje - do 45 dni naenkrat ali v več enakih obrokih',
        descriptionInfo2Mwf: 'Informacije o kreditnem posredniku in stroških',
        descriptionInfo2Ecommerce: 'Informacije o stroških',
        labelPayka: 'Payka',
        descriptionPayka: 'Plačajte račun kasneje - enkrat do 30 dni ali v 4 enakih obrokih <a href="" id="paykaShowInModal">Informacije o kreditnem posredniku in stroških</a>',
        descriptionBlikPayLater: 'Storitev je na voljo pri Millenium Bank in VeloBank',
        paykaModalMwf: {
          header1: 'Podatki o kreditnem posredniku',
          text1: 'Autopay S.A. s sedežem v Sopotu, deluje kot kreditni posrednik Paytree S.A. s sedežem v Gdansku (kreditodajalec), pooblaščen za predstavitev kreditne ponudbe strankam in preusmeritev na spletno stran kreditodajalca, vključno z vlogo za kredit. Podrobnosti o ponudbi na: <a href="https://payka.pl/rachunki-domowe" target="_blank">Rachunki domowe - Payka</a>.',
          header2: 'Podatki o stroških',
          text2: 'Plačajte kasneje - do 45 dni naenkrat ali v več enakih obrokih. Podatki o stroških za reprezentativen primer: odplačilo v 45 dneh APR 0 %; Obročno odplačevanje APR 86,83%. Podrobnosti o ponudbi na: <a href="https://payka.pl" target="_blank">Payka.pl</a>'
        },
        paykaModalEcommerce: {
          header2: 'Podatki o stroških',
          text2: 'Plačajte kasneje - do 45 dni naenkrat ali v več enakih obrokih. Podatki o stroških za reprezentativen primer: odplačilo v 45 dneh APR 0 %; Obročno odplačevanje APR 86,83%. Podrobnosti o ponudbi na: <a href="https://payka.pl" target="_blank">Payka.pl</a>'
        },
        blikPayLaterModal: {
          header1: 'Kupi zdaj, plačaj v 30 dneh',
          header2: 'BLIK koda',
          text2: 'Vnesete kodo BLIK in ponudimo vam omejitev nakupovanja do 4000 PLN',
          header3: 'Kupite izdelke in jih plačate pozneje',
          text3: 'Imate čas preveriti, če vam vse ustreza',
          header4: 'Plačaj v 30 dneh brezplačno',
          text4: 'Nakupe lahko tudi vrnete v roku, ki ga določi trgovina'
        }
      },
      companyPayments: {
        label: 'Plačila za podjetja',
        description: 'Prestavite datum plačila za nakupe podjetja za 30 dni'
      },
      transfer: {
        label: 'Podrobnosti bančnega nakazila',
        description: 'Izvedi prenos z uporabo posredovanih podatkov'
      },
      other: {
        label: 'Drugo',
        description: ''
      }
    },
    mobileEvents: {
      statusDescription: {
        bankDisabled: 'Žal. Trenutno ne moremo obdelati Tvojega prenosa. Izbrana banka trenutno ni navoljo',
        blockMultipleTransactions: 'Žal, transakcija že obstaja in čaka na plačilo.',
        blockPaidTransactions: 'Žal, transakcija je bila že plačana. Plačila ne moreš ponovno izvesti.',
        canceledTransactions: 'Žal, transakcija, ki jo želiš plačati, je bila preklicana in ni na voljo. Začni novo transakcijo.',
        declined: 'Avtorizacija zavrnjena.',
        generalError: 'Nastala je začasna težava s povezavo. Tvojega prenosa trenutno ne moremo obdelati. Prosimo, preveri kasneje.',
        insufficientStartAmount: 'Žal, omejitev zneska prenosa je bila presežena.',
        internalServerError: 'Upsss.. ni uspelo :(. Delamo na rešitvi težave. Prosimo, poskusi znova kasneje.',
        nonAccountedLimitExceeded: 'Plačila ni mogoče obdelati, ker je prodajalec dosegel mesečno omejitev prodaje.',
        outdatedError: 'Čas za dokončanje plačila je potekel..',
        paid: 'Transakcija je bila uspešno zaključena.',
        paywayLoaded: 'Stran za hiter prenos je bila naložena',
        paywaylistLoaded: 'Stran za izbiro načina plačila je naložena'
      }
    }
  },
  maintenance: {
    text1: 'V plačilnem portalu Autopay poteka novoletno čiščenje, zato trenutno ni mogoče naročiti plačila. Opravičujemo se za težave.',
    text2: 'Prehod bo ponovno odprt že 16. januarja ob 10:00.',
    text3: 'Hvala za razumevanje.'
  },
  requiredParams: {
    accountHolderName: {
      label: 'Povej mi svoje ime in priimek',
      flowLabel: 'ime in priimek',
      placeholder: 'Povej mi svoje ime in priimek'
    },
    nip: {
      label: 'Vnesite svojo davčno številko',
      flowLabel: 'NIP',
      placeholder: 'Vnesite svojo davčno številko'
    }
  }
}
